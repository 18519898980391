import './App.css';
import {Navigate} from 'react-router-dom';
import {BrowserRouter,Routes, Route} from "react-router-dom";
import ProtectedRoute from "./configuration/router/ProtectedRoute";
import NotFound from "./shared/component/page/NotFoundPage";
import InitDashboardGeneralPage from "./module/login/dashboard/page/InitDashboardGeneralPage";
import RetrieveGeneralPage from "./module/login/dashboard/page/RetrieveGeneralPage";
function App() {
  return (
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/init" element={<RetrieveGeneralPage/>} />
            <Route path="/dashboard" element={<InitDashboardGeneralPage/>} />
            {/*<Route*/}
            {/*    path="/dashboard"*/}
            {/*    element={*/}
            {/*        <ProtectedRoute>*/}
            {/*            <InitDashboardGeneralPage />*/}
            {/*        </ProtectedRoute>*/}
            {/*    }*/}
            {/*/>*/}


            {/* Route untuk halaman 404 */}
            <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
