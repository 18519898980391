export var HEADERS_AXIOS = {
    headers:{
        'Content-Type': 'application/json'
    }
};


export function headerWithAuthorization(auth?:string){
    return {
        headers:{
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + auth
        }
    };
}


export var REACT_APP_SUCCESS_CODE='BILANS-0000-SUCCESS';
export var REACT_APP_INVALID_JWT='BILANS-0000-JWT';
export var REACT_APP_EXPIRED_JWT='BILANS-0001-JWT';
export var REACT_APP_INVALID_PARAM='BILANS-0000-PARAM';
export var REACT_APP_INTERNAL_SERVER_ERROR='BILANS-INTERNAL_SERVER_ERROR';
export var REACT_APP_FORBIDDEN_ACCESS='BILANS-0000-FORBIDDEN';