
import { useLocation } from 'react-router-dom';
export const KEY = "_key";
export const IDS = "_urlIds";
export const SESSION_IDS = "_mySession";
export const MY_DATA_SSH = "_mydatassh_";
export const ACCESS_TOKEN = "_accessToken_";
export const DATA_DIRI = "_datadiri_";
export const FOTO_DATA_DIRI = "_fotodatadiri_";
export const REFRESH_TOKEN = "_refreshToken_";
export const EXPIRED_IN = "_expiredIn_";
export const MENU = "menu";
export const INFO = "info";

export function myUrl(): string {
    const protocol = window.location.protocol.replace(':','://');//
    const port = window.location.port==''?'':':'+window.location.port;
    return protocol+window.location.hostname+port;
}

export function paramValueOf(key:string): string {
    const currentUrl = window.location.href;
    const params = new URLSearchParams(currentUrl.split('?')[1]);
    let value = params.get(key) as string;
    if (value) {
        value = value.replace(/ /g, '+');
    }
    return value;
}

export function createObjectUrl(blobVal:Blob) : string{
    if (blobVal instanceof Blob) {
        return  URL.createObjectURL(blobVal);
    } else {
        throw new Error('Expected a Blob but received something else.');
    }
}

