import React, {useEffect, useState} from 'react';
import '../css/header-dashboard.css'
import {DataDiriResp} from "../../../module/login/dashboard/dto/DataDiriResp";
import {getAllMenuAndInfo, getDataDiri} from "../../../module/login/dashboard/service/RetrieveGeneralService";
import {ACCESS_TOKEN, createObjectUrl, DATA_DIRI, INFO, MENU, paramValueOf} from "../script/UtilService";
import {ResponseTemplate} from "../../dto/ResponseTemplate";
import {callGambarDataDiri, logoutMe} from "../../../module/login/dashboard/external/DashboardExternal";

interface HeaderProps {
    menuname?:string
}

const Header: React.FC<HeaderProps> = ({menuname}) => {
    const[token,setToken] = useState('');
    const[dataDiriResp,setDataDiriResp] = useState<DataDiriResp | null>(null);
    useEffect(()=>{




        const fetchDataDiri = async (token: string | null) => {
            try {

                let strdatadiri:string | null = localStorage.getItem(DATA_DIRI);
                if(typeof strdatadiri === "string") {
                    const dataDiri: ResponseTemplate<DataDiriResp> = JSON.parse(strdatadiri) as ResponseTemplate<DataDiriResp>;
                    const gambardiri:Blob= await callGambarDataDiri(dataDiri.responseOutput.linkFotoProfile);
                    let urlGambarDiri:string = createObjectUrl(gambardiri);
                    const updatedDataDiri =  { ...dataDiri.responseOutput, 'linkFotoProfile':urlGambarDiri } ;
                    setDataDiriResp(updatedDataDiri);
                }
                else {
                    let response: ResponseTemplate<DataDiriResp> = await getDataDiri(token);
                    setDataDiriResp(response.responseOutput);
                }


            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };


        let token: string | null =  localStorage.getItem(ACCESS_TOKEN);
        // @ts-ignore
        setToken(token);

        fetchDataDiri(token);


    },[]);



    return (
        <header className="dashboard-header">
            <div className="logo">
                <img src={dataDiriResp?.linkFotoProfile} alt="Logo" />
                <span className="name">Sobat {!dataDiriResp?.namaPanggilan?'Bilan':dataDiriResp.namaPanggilan}</span>
            </div>
            <nav>

                {
                    !!token?
                        <span>
                            <a href="#profile">Profile Saya</a>
                            <a href="#password">History Saya</a>
                            <a href="#logout" onClick={logoutMe}>Keluar</a>
                        </span>:
                        <a href={process.env.REACT_APP_INIT_URL}>login</a>
                }

            </nav>
        </header>
    );
};

export default Header;