import {LoginResp} from "../dto/LoginResp";
import {ResponseTemplate} from "../../../../shared/dto/ResponseTemplate";
import {isStatusResponseOk} from "../../../../shared/component/script/AxiosService";
import {callGambarDataDiri, callLoginUser, callMenuInfoUser, callSahabatDataDiri} from "../external/DashboardExternal";
import {ResponseCollection} from "../../../../shared/dto/ResponseCollection";
import {MenuInfoResp} from "../dto/MenuInfoResp";
import {
    ACCESS_TOKEN,
    createObjectUrl,
    DATA_DIRI,
    EXPIRED_IN, FOTO_DATA_DIRI, MY_DATA_SSH,
    REFRESH_TOKEN
} from "../../../../shared/component/script/UtilService";
import {DataDiriResp} from "../dto/DataDiriResp";

export async function getSessionId(sessionId: string): Promise<ResponseTemplate<LoginResp>> {
    try {
        const loginResp:ResponseTemplate<LoginResp> = await callLoginUser(sessionId);
        if(isStatusResponseOk(loginResp)) {
            setLocalStorage(loginResp,sessionId);

        }else{
            window.location.href = process.env.REACT_APP_INIT_URL!;
        }
        return loginResp;
    } catch (error) {
        alert('error on loginGeneral : '+ error)
        console.error('error on loginGeneral',error);
        throw new Error('Something went wrong! : '+ error);
    }
}

export async function getAllMenuAndInfo(path: string, auth?: string | null | undefined){
    try {
        const menuInfo:ResponseTemplate<ResponseCollection<MenuInfoResp>> = await callMenuInfoUser(path,auth);
        if(isStatusResponseOk(menuInfo)) {
            setLocalStorageMenuInfo(menuInfo,path);
        }
    } catch (error) {
        alert('error on loginGeneral : '+ error)
        console.error('error on loginGeneral',error);
        throw new Error('Something went wrong! : '+ error);
    }
}

export async function getDataDiri(auth?: string | null):Promise<ResponseTemplate<DataDiriResp>>{
    try {
        const dataDiri:ResponseTemplate<DataDiriResp> = await callSahabatDataDiri(auth);
        if(isStatusResponseOk(dataDiri)) {
            const gambardiri:Blob= await callGambarDataDiri(dataDiri.responseOutput.linkFotoProfile);
            let urlGambarDiri:string = createObjectUrl(gambardiri);
            const updatedDataDiri = { ...dataDiri, responseOutput: { ...dataDiri.responseOutput, 'linkFotoProfile':urlGambarDiri } };
            setLocalDataDiri(dataDiri);
            return updatedDataDiri;
        }
        return dataDiri;

    } catch (error) {
        alert('error on loginGeneral : '+ error)
        console.error('error on loginGeneral',error);
        throw new Error('Something went wrong! : '+ error);
    }
}

function setLocalDataDiri(datadiri: ResponseTemplate<DataDiriResp>): void {
    localStorage.setItem(DATA_DIRI,JSON.stringify(datadiri));
}

function setFotoLocalDataDiri(fotourl:string): void {
    localStorage.setItem(FOTO_DATA_DIRI,fotourl);
}

function setLocalStorageMenuInfo(menuInfo: ResponseTemplate<ResponseCollection<MenuInfoResp>>,path:string): void {
    const isValid = isStatusResponseOk(menuInfo);
    let pols : Array<MenuInfoResp> =  menuInfo.responseOutput.content;
    let values : Array<string> = [];

    pols.forEach(info => {
        values.push(info.picturePath);
    });

    if (isValid) {
        localStorage.setItem('_'+path+"_", values.join(", "));
    }

}

function setLocalStorage(loginResp: ResponseTemplate<LoginResp>,sessionId: string): void {
    const isValid = isStatusResponseOk(loginResp);

    if (isValid) {
        localStorage.setItem(ACCESS_TOKEN, loginResp.responseOutput.accessToken);
        localStorage.setItem(REFRESH_TOKEN, loginResp.responseOutput.refreshToken);
        localStorage.setItem(EXPIRED_IN, String(loginResp.responseOutput.expiresIn));
        localStorage.setItem(MY_DATA_SSH, sessionId);
    }

}