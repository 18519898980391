import {Navigate} from 'react-router-dom';
import React from 'react';
import {paramValueOf, SESSION_IDS} from "../../shared/component/script/UtilService";

const isAuthenticated = () => {
    const accessToken = localStorage.getItem('_accessToken_');
    const refreshToken = localStorage.getItem('_refreshToken_');
    const expiredIn = localStorage.getItem('_expiredIn_');
    return !!accessToken;
};

interface ProtectedRouteProps {
    children: React.ReactNode;
}

const ProtectedRoute:React.FC<ProtectedRouteProps> =  ({ children}) => {
    if (isAuthenticated()) {
        return <>{children}</>;
    } else {
        window.location.href =process.env.REACT_APP_INIT_URL!;
        return null;
    }
};

export default ProtectedRoute;
