import {useEffect} from "react";
import {paramValueOf, SESSION_IDS} from "../../../../shared/component/script/UtilService";
import { useNavigate } from 'react-router-dom';
import {getSessionId} from "../service/RetrieveGeneralService";

const RetrieveGeneralPage = () => {
    const navigate = useNavigate();


    useEffect(()=>{

        let sessionId: string | null = paramValueOf(SESSION_IDS);
        if(sessionId == null){
            sessionId =  localStorage.getItem(SESSION_IDS);
        }

        if (typeof sessionId === "string") {
            localStorage.setItem(SESSION_IDS, sessionId);
        }
        if(sessionId == null){
            window.location.href = process.env.REACT_APP_INIT_URL!;
        }else{
            getSessionId(sessionId).then(
                r => {
                    navigate('/dashboard')
                }
            ).catch(error=>{
                window.location.href = process.env.REACT_APP_INIT_URL!;
            });
        }
    },[]);

    return (
        <div>
          Hold On Tiger!!!
        </div>
    );
};

export default RetrieveGeneralPage;