// noinspection JSIgnoredPromiseFromCall

import Header from "../../../../shared/component/particel/Header";
import "../css/dashboard.css";
// @ts-ignore
import bilanLogo from "../../../../shared/component/logo/bilan-logo.png";
import {Key, useEffect, useRef, useState} from "react";
import {ACCESS_TOKEN, DATA_DIRI, INFO, MENU} from "../../../../shared/component/script/UtilService";
import {DataDiriResp} from "../dto/DataDiriResp";
import {Carousel} from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {getAllMenuAndInfo} from "../service/RetrieveGeneralService";

const InitDashboardGeneralPage = () => {
    const [infoMobilePaths, setInfoMobilePaths] = useState<Array<string>>([]);
    const [menuMobilePaths, setMenuMobilePaths] = useState<Array<string>>([]);
    const [infoWebPaths, setInfoWebPaths] = useState<Array<string>>([]);
    const [menuWebPaths, setMenuWebPaths] = useState<Array<string>>([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    // Set tipe untuk imagesRef
    const imagesMenuRef = useRef<(HTMLImageElement | null)[]>([]); // Array of HTMLImageElement or null
    const imagesInfoRef = useRef<(HTMLImageElement | null)[]>([]); // Array of HTMLImageElement or null

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {

        window.addEventListener('resize', handleResize);

        let token: string | null = localStorage.getItem(ACCESS_TOKEN);

        const fetchMenuInfo = async (path: string, token?: string | null) => {
            try {
                await getAllMenuAndInfo(path, token);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchMenuInfo(INFO, token).then((value => {
            let menuStr: string | null = localStorage.getItem("_" + MENU + "_");
            if (menuStr != null) {
                const menuMobilePaths: Array<string> = [];
                const menuWebPaths: Array<string> = [];

                menuStr.split(", ").map(path => {
                        if (path.includes('MenuMobile')) {
                            menuMobilePaths.push(path.trim());
                        } else if (path.includes('MenuWeb')) {
                            menuWebPaths.push(path.trim());
                        }
                    }
                );

                setMenuMobilePaths(menuMobilePaths);
                setMenuWebPaths(menuWebPaths);

            }

            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }));

        fetchMenuInfo(MENU, token).then(value => {
            let infoStr: string | null = localStorage.getItem("_" + INFO + "_");
            if (infoStr != null) {
                const infoMobilePaths: Array<string> = [];
                const infoWebPaths: Array<string> = [];

                infoStr.split(", ").map(path => {
                        if (path.includes('InfoMobile')) {
                            infoMobilePaths.push(path.trim());
                        } else if (path.includes('InfoWeb')) {
                            infoWebPaths.push(path.trim());
                        }
                    }
                );

                setInfoMobilePaths(infoMobilePaths);
                setInfoWebPaths(infoWebPaths);
            }
        });

        console.log("run info menu first.");
        return () => {
            window.removeEventListener('resize', handleResize);
        };


    }, []);



    useEffect(() => {
        // Mengatur observer untuk animasi gambar
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('visible'); // Add class when entering
                    } else {
                        entry.target.classList.remove('visible'); // Remove class when exiting
                    }
                });
            },
            { threshold: 0.1 }
        );

        imagesMenuRef.current.forEach(img => {
            console.log("set value : ", img);
            if (img) {
                img.classList.add('visible'); // Set visible on initial render
                observer.observe(img); // Observe for scrolling
            }
        });

        imagesInfoRef.current.forEach(img => {
            console.log("set value : ", img);
            if (img) {
                img.classList.add('visible'); // Set visible on initial render
                observer.observe(img); // Observe for scrolling
            }
        });


        console.log("run after render second.");

        // Cleanup observer on unmount
        return () => {
            imagesMenuRef.current.forEach(img => {
                if (img) observer.unobserve(img);
            });

            imagesInfoRef.current.forEach(img => {
                if (img) observer.unobserve(img);
            });
        };

    }, [menuMobilePaths, menuWebPaths, infoMobilePaths, infoWebPaths,isMobile]); // Jalankan lagi saat paths berubah

    const renderMenuImages = (paths: any[]) =>
        paths.map((img: any, index: any) => {

            return (
            <img
                key={index}
                ref={el => imagesMenuRef.current[index] = el} // simpan referensi ke dalam array
                className="image-show"
                src={`${process.env.REACT_APP_LOGIN_API_URL}/dashboard/external/picture?_name=${img}`}
                alt={`Blob Image ${index + 1}`}
            />
        )});

    const renderInfoImages = (paths: any[]) =>
        paths.map((img: any, index: any) => (
            <img
                key={index}
                ref={el => imagesInfoRef.current[index] = el} // simpan referensi ke dalam array
                className="image-show"
                src={`${process.env.REACT_APP_LOGIN_API_URL}/dashboard/external/picture?_name=${img}`}
                alt={`Blob Image ${index + 1}`}
            />
        ));

    return (
        <div className="container">
            <div className="content">
                <Header/>

                <div className="dashboard-item">
                    <div className="splitter-item"/>
                    <div className="menu-bagan">
                        {isMobile ? (
                            <>
                                {renderMenuImages(menuMobilePaths)}
                                {renderInfoImages(infoMobilePaths)}
                            </>
                        ) : (
                            <>
                                {renderMenuImages(menuWebPaths)}
                                {renderInfoImages(infoWebPaths)}
                            </>
                        )}
                    </div>

                    <div className="dashboard-footer">
                        <div className="followus-dashboard">
                            <p>Follow Us On : </p>
                            <div className="list">
                            <a>Linkedn</a>
                            <a>Instagram</a>
                            <a>Youtube</a>
                            <a>Whatsapp</a>
                            </div>
                        </div>

                        <div className="dashboard-divisor">
                            <div className="sendme-tips-dashboard">
                                <p>Kritik Dan Saran : </p>
                                <button>Klik Me</button>
                            </div>
                            <div className="logo-dashboard">
                                <p className="powered">powered by :</p>
                                <img className="bilan-logo" src={bilanLogo}  />
                            </div>
                        </div>
                    </div>



                </div>


            </div>
        </div>
    );
};

export default InitDashboardGeneralPage;