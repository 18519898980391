import {LoginResp} from "../dto/LoginResp";
import {ResponseTemplate} from "../../../../shared/dto/ResponseTemplate";
import axios from "axios";
import {HEADERS_AXIOS, headerWithAuthorization} from "../../../../shared/constant/GlobalVariable";
import {ResponseCollection} from "../../../../shared/dto/ResponseCollection";
import {MenuInfoResp} from "../dto/MenuInfoResp";
import {DataDiriResp} from "../dto/DataDiriResp";

export async function callLoginUser(sessionId:string): Promise<ResponseTemplate<LoginResp>> {
    var uri = process.env.REACT_APP_LOGIN_API_URL+'/general/auth/getSession?_mySession='+sessionId;
    try {
        const response = await axios.get<ResponseTemplate<LoginResp>>(uri,HEADERS_AXIOS);
        return response.data;
    } catch (error) {
        //TODO:Buatkan UI untuk menampilkan kesalahan ketika hit api.
        throw new Error('Unable To Called Api');
    }
}

export async function callMenuInfoUser(path: string, auth?: string | null | undefined): Promise<ResponseTemplate<ResponseCollection<MenuInfoResp>>> {
    const uri = process.env.REACT_APP_LOGIN_API_URL + '/dashboard/external/' + path;
    try {
        let response;
        if(auth) {
            response = await axios.get<ResponseTemplate<ResponseCollection<MenuInfoResp>>>(uri, headerWithAuthorization(auth));
        }else{
            response = await axios.get<ResponseTemplate<ResponseCollection<MenuInfoResp>>>(uri, HEADERS_AXIOS);
        }
        return response.data;
    } catch (error) {
        //TODO:Buatkan UI untuk menampilkan kesalahan ketika hit api.
        throw new Error('Unable To Called Api');
    }
}

export async function callSahabatDataDiri(auth?: string | null | undefined): Promise<ResponseTemplate<DataDiriResp>> {
    const uri = process.env.REACT_APP_LOGIN_API_URL + '/general/sahabat-data-diri/external/me';
    try {
        let response;
        if(auth) {
            response = await axios.get<ResponseTemplate<DataDiriResp>>(uri, headerWithAuthorization(auth));
        }else{
            response = await axios.get<ResponseTemplate<DataDiriResp>>(uri, HEADERS_AXIOS);
        }
        return response.data;
    } catch (error) {
        //TODO:Buatkan UI untuk menampilkan kesalahan ketika hit api.
        throw new Error('Unable To Called Api');
    }
}


export async function callGambarDataDiri(path: string): Promise<Blob> {
    const uri = process.env.REACT_APP_LOGIN_API_URL + '/general/sahabat-data-diri/external/picture?_name=' + path;
    try {
        const response = await axios.get<Blob>(uri,{
            ...HEADERS_AXIOS,
            responseType: 'blob'
        });
        return response.data;
    } catch (error) {
        //TODO:Buatkan UI untuk menampilkan kesalahan ketika hit api.
        throw new Error('Unable To Called Api');
    }
}

export async function callGambarExternal(path: string): Promise<Blob> {
    const uri = process.env.REACT_APP_LOGIN_API_URL + '/dashboard/external/picture?_name=' + path;
    try {
        const response = await axios.get<Blob>(uri,{
            ...HEADERS_AXIOS,
            responseType: 'blob'
        });
        return response.data;
    } catch (error) {
        //TODO:Buatkan UI untuk menampilkan kesalahan ketika hit api.
        throw new Error('Unable To Called Api');
    }
}

export async function logoutMe(){
    localStorage.clear();
    window.location.reload();
}